import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/fashion/fashion-1.jpg',
  '../img/fashion/fashion-2.jpg',
  '../img/fashion/fashion-3.jpg',
  '../img/fashion/fashion-6.jpg',
  '../img/fashion/fashion-5.jpg',
  '../img/fashion/fashion-8.jpg',
  '../img/fashion/fashion-10.jpg',
  '../img/fashion/fashion-11.jpg',
  '../img/fashion/fashion-12.jpg',
  '../img/fashion/IMG-0036.jpg',
  '../img/fashion/IMG-0039.jpg',
  '../img/fashion/IMG-0015.jpg',
  '../img/fashion/IMG-0017.jpg',
  '../img/fashion/IMG-0021.jpg',
  '../img/fashion/IMG-0025.jpg',
  '../img/fashion/IMG-0128.jpg',
  '../img/fashion/IMG-0137.jpg',
  '../img/fashion/IMG-0143.jpg',
  '../img/fashion/IMG-0236.jpg',
  '../img/fashion/IMG-0260.jpg',
  '../img/fashion/IMG-0263.jpg',
  '../img/fashion/IMG-0377.jpg',
  '../img/fashion/IMG-0330.jpg',
  '../img/fashion/IMG-0328.jpg',
  '../img/fashion/IMG-0327.jpg',
];

const Fashion = () => (
  <Aux>
    <Helmet>
      <title>fashion</title>
      <meta name="description" content="page fashion" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Fashion;
