import React from 'react';
import ReactCompareImage from 'react-compare-image';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';
import Container from 'react-bootstrap/Container'

// const images = [
//   {id: 1, src: '../img/retouch/brut-1.jpg'},
//   {id: 2, src: '../img/retouch/retouch-2.jpg'},
// ];
// const [leftImageSrc, setLeftImageSrc] = useState(img1Src);
// const [rightImageSrc, setRightImageSrc] = useState(img2Src);

const Retouch = () => (
  <Aux>
    <Helmet>
    <title>Retouching</title>
    <meta name="description" content="page retouching" />
    </Helmet>
    <Container fluid="xl">
      <div className="row">
        <div className="col-12">
          <ReactCompareImage
            leftImage='../img/retouch/brut-1.jpg'
            rightImage='../img/retouch/retouch-1.jpg'
          />
          <br/>
        </div>
        <div className="col-12">
          <ReactCompareImage
            leftImage='../img/retouch/brut-2.jpg'
            rightImage='../img/retouch/retouch-2.jpg'
          />
          <br/>
        </div>
      </div>
    </Container>
  </Aux>
);

export default Retouch;
