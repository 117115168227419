import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/pinup/pinup-10.jpg',
  '../img/pinup/pinup-11.jpg',
  '../img/pinup/pinup-1.jpg',
  '../img/pinup/pinup-2.jpg',
  '../img/pinup/pinup-3.jpg',
  '../img/pinup/pinup-4.jpg',
  '../img/pinup/pinup-5.jpg',
  '../img/pinup/pinup-6.jpg',
  '../img/pinup/pinup-7.jpg',
  '../img/pinup/pinup-8.jpg',
];

const Pinup = () => (
  <Aux>
    <Helmet>
      <title>Pin-Up</title>
      <meta name="description" content="page pinup" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Pinup;
