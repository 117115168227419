import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/skinandfur/IMG-0517.jpg',
  '../img/skinandfur/IMG-0519.jpg',
  '../img/skinandfur/IMG-0520.jpg',
];

const Skinandfur = () => (
  <Aux>
    <Helmet>
      <title>Skin & Fur</title>
      <meta name="description" content="page skin and fur" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Skinandfur;
