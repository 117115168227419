import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/darkknight/IMG-0452.jpg',
  '../img/darkknight/IMG-0453.jpg',
  '../img/darkknight/IMG-0454.jpg',
  '../img/darkknight/IMG-0496.jpg',
  '../img/darkknight/IMG-0499.jpg',
  '../img/darkknight/IMG-0500.jpg',
];

const DarkKnight = () => (
  <Aux>
    <Helmet>
      <title>Dark Knight</title>
      <meta name="description" content="page dark knight" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default DarkKnight;
