import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/wolfdog/IMG-0441.jpg',
  '../img/wolfdog/IMG-0466.jpg',
  '../img/wolfdog/IMG-0467.jpg',
  '../img/wolfdog/IMG-0521.jpg',
  '../img/wolfdog/IMG-0523.jpg',
  '../img/wolfdog/IMG-0524.jpg',
  '../img/wolfdog/IMG-0525.jpg',
  '../img/wolfdog/IMG-0526.jpg',
  '../img/wolfdog/IMG-0528.jpg',
];

const Wolfdog = () => (
  <Aux>
    <Helmet>
      <title>Behind Blue Eyes</title>
      <meta name="description" content="page wolfdog" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Wolfdog;
