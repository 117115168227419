import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga";  //Google Analytics
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';

require('dotenv').config();
// const hist = createHashHistory();

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.initialize(process.env.ID_GA_2);
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname + window.location.search); // Record a pageview for the given page
});

ReactDOM.render(
  <Router history={history}>
   <App/>
  </Router>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
