import React from 'react';

// import '../styles/css/home.css';

//const images = []

const GridCards = ({images}) => (
  <div className="container col-12">
    <div className="card-columns">
      {images.map(function(item, index) {
        return(
          <div className="card" key={index} width="20%">
            <img className="card-img-top" src={item.src} alt={item.alt} />
          </div>
        );
      })}
    </div>
  </div>
);

export default GridCards;
