import React from 'react';
//import {Container, Row, Col, Image} from 'react-bootstrap';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/boudoir/IMG-0029.jpg',
  '../img/boudoir/IMG-0215.jpg',
  '../img/boudoir/boudoir-nu-11594.jpg',
  '../img/boudoir/boudoir-robe-11315.jpg',
];

const Boudoir = () => (
  <Aux>
    <Helmet>
    <title>Boudoir</title>
    <meta name="description" content="page boudoir" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Boudoir;
