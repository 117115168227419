import  { React, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Masonry from 'react-masonry-css';
import { Image } from 'react-bootstrap';

import '../styles/css/gridmasonry.css';

const GridMasonry = ({images}) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const breakpointCol = {
    default: 4,
    3000: 3,
    1500: 2,
    750: 1,
  };

  const options = {
    transitionDuration: 0
  };

  return (
    <div>
      <div>
        <Masonry
          breakpointCols={breakpointCol}
          className="my-masonry-grid"
          options={options}
          columnClassName="my-masonry-grid_column">
          {images.map(function(item, index) {
            return(
              <div key={index} onClick={() => setPhotoIndex(index)}>
                <Image src={item} alt="" fluid={true} onClick={() => setIsOpen(true)}/>
              </div>
            );
          })}
        </Masonry>
      </div>
      {isOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  )
}

export default GridMasonry;
