import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Lazyload from "react-lazyload";

import '../styles/css/layout.css';

const Layout = (props) => {
  return(
    <div className="mx-auto">
      <Header/>
        {props.children}
    </div>
  );
}

export default Layout;
