import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

// const images = [
//   {src: '../img/danse/IMG-0213.jpg'},
//   {src: '../img/danse/IMG-0219.jpg'},
//   {src: '../img/danse/IMG-0226.jpg'},
//   {src: '../img/danse/IMG-0279.jpg'},
//   {src: '../img/danse/IMG-0280.jpg'},
//   {src: '../img/danse/IMG-0281.jpg'},
// ];

const images = [
  '../img/danse/danse-1.jpg',
  '../img/danse/danse-2.jpg',
  '../img/danse/danse-3.jpg',
  '../img/danse/IMG-0213.jpg',
  '../img/danse/IMG-0219.jpg',
  '../img/danse/IMG-0226.jpg',
  '../img/danse/IMG-0279.jpg',
  '../img/danse/IMG-0280.jpg',
  '../img/danse/IMG-0281.jpg',
];


const Danse = () => (
  <Aux>
    <Helmet>
      <title>Danse</title>
      <meta name="description" content="page danse" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Danse;
