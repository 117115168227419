import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/voile/veil-1.jpg',
  '../img/voile/veil-2.jpg',
  '../img/voile/veil-3.jpg',
  '../img/voile/veil-4.jpg',
];

const Voile = () => (
  <Aux>
    <Helmet>
      <title>Derrière Le Voile</title>
      <meta name="description" content="page derrière le voile" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Voile;
