import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/portrait/portrait-4.jpg',
  '../img/portrait/portrait-1.jpg',
  '../img/portrait/portrait-2.jpg',
  '../img/portrait/portrait-3.jpg',
  '../img/portrait/portrait-5.jpg',
   '../img/portrait/IMG-0472.jpg',
   '../img/portrait/IMG-0471.jpg',
   '../img/portrait/IMG-0487.jpg',
   '../img/portrait/IMG-0493.jpg',
   '../img/portrait/IMG-0065.jpg',
   '../img/portrait/IMG-0067.jpg',
   '../img/portrait/IMG-0049.jpg',
   '../img/portrait/IMG-0050.jpg',
   '../img/portrait/IMG-0293.jpg',
   '../img/portrait/IMG-0297.jpg',
   '../img/portrait/IMG-0305.jpg',
   '../img/portrait/IMG-0328.jpg',
   '../img/portrait/IMG-0379.jpg',
   '../img/portrait/IMG-0384.jpg',
   '../img/portrait/IMG-0399.jpg',
   '../img/portrait/IMG-0133.jpg',
   '../img/portrait/IMG-0041.jpg',
   '../img/portrait/IMG-0108.jpg',
   '../img/portrait/IMG-0112.jpg',
   '../img/portrait/IMG-0317.jpg',
   '../img/portrait/IMG-0324.jpg',
   '../img/portrait/IMG-0025.jpg',
   '../img/portrait/IMG-0026.jpg',
];

const Portrait = () => (
  <Aux>
    <Helmet>
      <title>Portrait</title>
      <meta name="description" content="page portrait" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Portrait;
