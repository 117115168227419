import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/neon/IMG-0011.jpg',
  '../img/neon/IMG-0488.jpg',
  '../img/neon/IMG-0489.jpg',
  '../img/neon/IMG-0490.jpg',
  '../img/neon/IMG-0076.jpg',
  '../img/neon/IMG-0077.jpg',
  '../img/neon/IMG-0078.jpg',
  '../img/neon/IMG-0096.jpg',
  '../img/neon/IMG-0491.jpg',
];

const Neon= () => (
  <Aux>
    <Helmet>
      <title>Neon</title>
      <meta name="description" content="page néon" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Neon;
