import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/lingerie/lingerie-1.jpg',
  '../img/lingerie/lingerie-3.jpg',
  '../img/lingerie/lingerie-4.jpg',
  '../img/lingerie/lingerie-5.jpg',
  '../img/lingerie/lingerie-6.jpg',
  '../img/lingerie/lingerie-7.jpg',
  '../img/lingerie/lingerie-8.jpg',
  '../img/lingerie/IMG-0292.jpg',
  '../img/lingerie/IMG-0331.jpg',
  '../img/lingerie/IMG-0334.jpg',
  '../img/lingerie/IMG-0351.jpg',
  '../img/lingerie/IMG-0352.jpg',
  '../img/lingerie/IMG-0012.jpg',
  '../img/lingerie/IMG-0016.jpg',
  '../img/lingerie/IMG-0321.jpg',
  '../img/lingerie/IMG-0322.jpg',
];

const Lingerie = () => (
  <Aux>
    <Helmet>
      <title>Lingerie</title>
      <meta name="description" content="page lingerie" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Lingerie;
