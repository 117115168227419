import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/shibari/IMG-0222.jpg',
  '../img/shibari/IMG-0225.jpg',
  '../img/shibari/IMG-0233.jpg',
  '../img/shibari/IMG-0240.jpg',
  '../img/shibari/IMG-0244.jpg',
  '../img/shibari/IMG-0238.jpg',
  '../img/shibari/IMG-0069.jpg',
  '../img/shibari/IMG-0070.jpg',
];

const Shibari = () => (
  <Aux>
    <Helmet>
      <title>Shibari</title>
      <meta name="description" content="page shibari" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Shibari;
