import React from 'react';
import Helmet from "react-helmet";
import { Container, Row, Col, Image } from 'react-bootstrap';
import Aux from '../hoc/Auxiliary';

import '../styles/css/about.css';

const About = () => {
  return(
    <Aux>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About me informations" />
      </Helmet>
      <Container id="about" fluid="xl">
        <Row>
          <Col>
            <Image src="../Profil.jpg" alt="" width="85%" />
          </Col>
          <Col>
            <h6>
              En quelques mots, qui suis-je ?
            </h6>
            <h6>
              Photographe basé à Paris mais pouvant également se déplacer en France comme à l'étranger.
            </h6>
            <h6>
              Je me suis spécialisé dans la photographie d'auteur mettant en lumière ma créativité et mes diverses influences.
            </h6>
            <h6>
              Mes styles allant du portrait à la mise en scène dans un décor existant ou créé pour l'occasion,
              je me ferais une joie de vous constituer .
            </h6>
            <h6>
              Ayant commencé par la photographie animalière, je réalise également des shooting
              avec des animaux (chien loup, cheval, reptiles, oiseaux) retranscrivant la synergie entre l'humain et l'animal.
            </h6>
            <h6>
              une ambiance retranscrivant un courant artistique, une histoire, une sensation.
            </h6>
            <h6>
              Vous retrouverez également dans mes travaux des photos s'inspirant de la peinture (Caravage),
              du cinéma Hollywoodien des années 30 allant de paire avec le mouvment pin-up
              parfaitement incarné par Dita Von Teese et le cabaret burlesque.
            </h6>
            <h6>
              Pour toute demande concernant un shooting, n'hésitez pas à me contacter via le formulaire dans la rubrique Contact.
            </h6>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
};

export default About;
