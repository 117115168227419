import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/nu/IMG-0007.jpg',
  '../img/nu/IMG-0009.jpg',
  '../img/nu/IMG-0025.jpg',
  '../img/nu/IMG-0024.jpg',
  '../img/nu/IMG-0018.jpg',
  '../img/nu/IMG-0168.jpg',
  '../img/nu/IMG-0178.jpg',
  '../img/nu/IMG-0211.jpg',
  '../img/nu/IMG-0221.jpg',
  '../img/nu/IMG-0302.jpg',
  '../img/nu/IMG-0308.jpg',
  '../img/nu/IMG-0354.jpg',
  '../img/nu/IMG-0357.jpg',
  '../img/nu/IMG-0106.jpg',
  '../img/nu/IMG-0251.jpg',
  '../img/nu/IMG-0098.jpg',
];

const Nu = () => (
  <Aux>
    <Helmet>
      <title>Nu</title>
      <meta name="description" content="page nu" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Nu;
