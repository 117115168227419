import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  {},
];

const Jackleventreur = () => (
  <Aux>
    <Helmet>
      <title>Jack L'Eventreur</title>
      <meta name="description" content="page" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Jackleventreur;
