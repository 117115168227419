import React from 'react';
import Helmet from "react-helmet";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Aux from '../hoc/Auxiliary';

import '../styles/css/contactsimple.css';

// const uuid = require('uuid/v4');

const Contact = () => {
  return(
    <Aux>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact me page" />
      </Helmet>
      <Container className="container" id="contactsimple">
        <Row>
          <Col xs="4">
            <Image src="../Profil.jpg" alt="" fluid={true} />
          </Col>
          <Col xs="6">
            <h5>Contact Me</h5>
            <br/>
            <p>
              <h6>
                Vous souhaitez discutez d'un projet en particulier, avoir de plus amples informations ?
              </h6>
            </p>
            <p>
              <h6>
                Je me ferais un plaisir d'en discuter avec vous afin de mieux cerner vos attentes
              </h6>
            </p>
            <p>
              <h6>
                N'hésitez pas à m'écrire à l'aide du boutton que vous trouverez ci-dessous
              </h6>
            </p>
            <p>
              <Button variant="primary">
                <a href="mailto:contact@adamproust.com" style={{color:"white"}}>Contact</a>
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
};

export default Contact;
