import React from 'react';
//import {Container, Row, Col, Image} from 'react-bootstrap';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/hollywood/IMG-0041.jpg',
  '../img/hollywood/IMG-0065.jpg',
  '../img/hollywood/IMG-0067.jpg',
  '../img/hollywood/IMG-0068.jpg',
  '../img/hollywood/IMG-0487.jpg',
  '../img/hollywood/IMG-0326.jpg',
  '../img/hollywood/IMG-0324.jpg',
];

const Hollywood = () => (
  <Aux>
    <Helmet>
    <title>hollywood</title>
    <meta name="description" content="page hollywood" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Hollywood;
