import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/burlesque/burlesque-1.jpg',
  '../img/burlesque/burlesque-2.jpg',
  '../img/burlesque/burlesque-3.jpg',
  '../img/burlesque/burlesque-4.jpg',
  '../img/burlesque/burlesque-5.jpg',
  '../img/burlesque/burlesque-6.jpg',
  '../img/burlesque/burlesque-7.jpg',
  '../img/burlesque/burlesque-8.jpg',
  '../img/burlesque/burlesque-9.jpg',
  '../img/burlesque/burlesque-10.jpg',
];

const Burlesque = () => (
  <Aux>
    <Helmet>
      <title>Pin-Up</title>
      <meta name="description" content="page burlesque" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Burlesque;
