import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/robertoferri/roberto-ferri-1.jpg',
  '../img/robertoferri/roberto-ferri-2.jpg',
  '../img/robertoferri/roberto-ferri-3.jpg',
];

const Robertoferri = () => (
  <Aux>
    <Helmet>
      <title>Roberto Ferri</title>
      <meta name="description" content="page roberto ferri" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Robertoferri;
