import { Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import '../styles/css/header.css';

import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/ContactSimple';
//import Video from '../pages/Video';

// Portfolio
import Portrait from '../pages/Portrait';
import Fashion from '../pages/Fashion';
import Lingerie from '../pages/Lingerie';
import Nu from '../pages/Nu';
import Fire from '../pages/Fire';

// Series
import Danse from '../pages/Danse';
import Boudoir from '../pages/Boudoir';
import Crazyhorse from '../pages/Crazyhorse';
import Wolfdog from '../pages/Wolfdog';
import Jackleventreur from '../pages/Jackleventreur';
import Neon from '../pages/Neon';
import Shibari from '../pages/Shibari';
import Robertoferri from '../pages/Robertoferri';
import Peinture from '../pages/Peinture';
import DarkKnight from '../pages/DarkKnight';
import Godofglass from '../pages/Godofglass';
import Redroom from '../pages/Redroom';
import Skinandfur from '../pages/Skinandfur';
import GreekGodess from '../pages/GreekGodess';
import Hollywood from '../pages/Hollywood';
import Voile from '../pages/Voile';
import Pinup from '../pages/Pinup';
import Burlesque from '../pages/Burlesque';

// import Print from '../pages/Print';
import Retouch from '../pages/Retouch';
// import Conditions from '../pages/Conditions';

const Header = () => {
  return (
    <BrowserRouter>
      <Navbar collapseOnSelect expand="md" bg="transparent" variant="light" className="p-2 navbar">
        <Navbar.Brand as={Link} to="/home">
          <Image src="./logo2.jpg" className="d-inline-block align-top" alt="logo" width="125vw"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" variant="light">
            <Nav.Link as={Link} to="/home">Home</Nav.Link>
            <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/portfolio/portrait">Portrait</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="portfolio/fashion">Fashion</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/portfolio/fire">Fire</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/portfolio/lingerie">Lingerie</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/portfolio/nu">Nu</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Series" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="series/danse">Danse</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="series/burlesque">Burlesque</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="series/pinup">Pin-Up</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/crazyhorse">Crazy Horse</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/peinture">Peinture</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/darkKnight">Dark Knight</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/boudoir">Boudoir</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/neon">Néon</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/behindblueeyes">Behind Blue Eyes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/shibari">Shibari</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/robertoferri">Tribute to Roberto Ferri</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/godofglass">God of Glass</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/redroom">Red Room</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/skinandfur">Skin & Fur</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/greekGodess">Greek Godess</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/hollywood">Hollywood Glamour</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/series/voile">Behind The Veil</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/retouch">Retouching</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/portfolio/portrait" exact component={Portrait} />
        <Route path="/portfolio/fashion" exact component={Fashion} />
        <Route path="/portfolio/lingerie" exact component={Lingerie} />
        <Route path="/portfolio/nu" exact component={Nu} />
        <Route path="/portfolio/fire" exact component={Fire} />
        <Route path="/series/danse" exact component={Danse} />
        <Route path="/series/pinup" exact component={Pinup} />
        <Route path="/series/burlesque" exact component={Burlesque} />
        <Route path="/series/crazyhorse" exact component={Crazyhorse} />
        <Route path="/series/behindblueeyes" exact component={Wolfdog} />
        <Route path="/series/jackleventreur" exact component={Jackleventreur} />
        <Route path="/series/boudoir" exact component={Boudoir} />
        <Route path="/series/shibari" exact component={Shibari} />
        <Route path="/series/robertoferri" exact component={Robertoferri} />
        <Route path="/series/peinture" exact component={Peinture} />
        <Route path="/series/darkknight" exact component={DarkKnight} />
        <Route path="/series/godofglass" exact component={Godofglass} />
        <Route path="/series/neon" exact component={Neon} />
        <Route path="/series/redroom" exact component={Redroom} />
        <Route path="/series/skinandfur" exact component={Skinandfur} />
        <Route path="/series/greekgodess" exact component={GreekGodess} />
        <Route path="/series/hollywood" exact component={Hollywood} />
        <Route path="/series/voile" exact component={Voile} />
        <Route path="/retouch" exact component={Retouch} />
        <Redirect to='/home' />
      </Switch>
    </BrowserRouter>
  );
}

export default Header;
