import React from 'react';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';
import GridCards from '../components/GridCards';
import Lazyload from "react-lazyload";
import Container from 'react-bootstrap/Container'

// import Footer from '../components/Footer';

// import '../styles/css/home.css';

const images = [
  {src: '../img/peinture/peinture-1.jpg', alt: 'peinture', album: 'peinture'},
  {src: '../img/peinture/peinture-2.jpg', alt: 'peinture', album: 'peinture'},
  {src: '../img/peinture/peinture-4.jpg', alt: 'peinture', album: 'peinture'},
  {src: '../img/pinup/pinup-10.jpg', alt: "pinup", album: 'pinup'},
  {src: '../img/portrait/portrait-4.jpg', alt: "portrait", album: 'portrait'},
  {src: '../img/portrait/portrait-1.jpg', alt: "portrait", album: 'portrait'},
  {src: '../img/portrait/portrait-5.jpg', alt: "portrait", album: 'portrait'},
  {src: '../img/fashion/fashion-2.jpg', alt: "fashion", album: 'fashion'},
  {src: '../img/fashion/fashion-3.jpg', alt: "fashion", album: 'fashion'},
  {src: '../img/fashion/fashion-6.jpg', alt: "fashion", album: 'fashion'},
  {src: '../img/lingerie/lingerie-4.jpg', alt:"Lingerie", album: 'Lingerie'},
  {src: '../img/007/0X8A7044.jpg', alt: "007", album: '007'},
  {src: '../img/007/0X8A6902.jpg', alt: "007", album: '007'},
  {src: '../img/danse/IMG-0213.jpg', alt: "danse", album: 'danse'},
  {src: '../img/danse/IMG-0230.jpg', alt: "danse", album: 'danse'},
  {src: '../img/danse/IMG-0280.jpg', alt: "danse", album: 'danse'},
  {src: '../img/danse/IMG-0279.jpg', alt: "danse", album: 'danse'},
  {src: '../img/pinup/pinup-1.jpg', alt: "pin-up", album: 'pinup'},
  {src: '../img/portrait/IMG-0041.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0135.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0297.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0324.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0328.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0384.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0487.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0050.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/portrait/IMG-0112.jpg', alt:"Portrait", album: 'Portrait'},
  {src: '../img/fashion/IMG-0021.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/fashion/IMG-0035.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/fashion/IMG-0127.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/fashion/IMG-0137.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/fashion/IMG-0330.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/fashion/IMG-0036.jpg', alt: 'fashion', album: 'fashion'},
  {src: '../img/boudoir/boudoir-nu-11594.jpg', alt: "Boudoir Nu", album: 'Boudoir'},
  {src: '../img/greekgodess/IMG-0020.jpg', alt:"Greek Godess", album: 'Greekgodess'},
  {src: '../img/greekgodess/IMG-0021.jpg', alt:"Greek Godess", album: 'Greekgodess'},
  {src: '../img/wolfdog/IMG-0441.jpg', alt:"Wolfdog", album: 'Wolfdog'},
  {src: '../img/lingerie/IMG-0011.jpg', alt:"Lingerie", album: 'Lingerie'},
  {src: '../img/lingerie/IMG-0012.jpg', alt:"Lingerie", album: 'Lingerie'},
  {src: '../img/lingerie/IMG-0016.jpg', alt:"Lingerie", album: 'Lingerie'},
  {src: '../img/lingerie/IMG-0334.jpg', alt:"Lingerie", album: 'Lingerie'},
];

const Home = () => (
  <Aux>
    <Helmet>
      <title>Home</title>
      <meta name="description" content="Home page Adam Proust Photographe" />
    </Helmet>
    <Lazyload>
      <Container fluid="xl" >
        <GridCards images={images}/>
      </Container>
      <br/>
    </Lazyload>
  </Aux>
);

export default Home;
