import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/greekgodess/IMG-0017.jpg',
  '../img/greekgodess/IMG-0529.jpg',
  '../img/greekgodess/IMG-0532.jpg',
  '../img/greekgodess/IMG-0531.jpg',
  '../img/greekgodess/IMG-0020.jpg',
  '../img/greekgodess/IMG-0021.jpg',
];

const GreekGodess = () => (
  <Aux>
    <Helmet>
      <title>Greek Godess</title>
      <meta name="description" content="page greek goddess" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default GreekGodess;
