import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/ero/IMG-0287.jpg',
  '../img/ero/IMG-0288.jpg',
  '../img/ero/IMG-0290.jpg',
  '../img/ero/IMG-0285.jpg',
  '../img/ero/IMG-0283.jpg'
];

const Godofglass = () => (
  <Aux>
    <Helmet>
      <title>Gode of Glass</title>
      <meta name="description" content="page érotique" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Godofglass;
