import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/redroom/IMG-0004.jpg',
  '../img/redroom/IMG-0007.jpg',
  '../img/redroom/IMG-0005.jpg',
  '../img/redroom/IMG-0002.jpg',
  '../img/redroom/IMG-0080.jpg',
  '../img/redroom/IMG-0488.jpg',
  '../img/redroom/IMG-0020.jpg',
];

const Redroom = () => (
  <Aux>
    <Helmet>
      <title>Red Room</title>
      <meta name="description" content="page red room" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Redroom;
