import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/crazyhorse/IMG-0001.jpg',
  '../img/crazyhorse/IMG-0002.jpg',
  '../img/crazyhorse/IMG-0003.jpg',
  '../img/crazyhorse/IMG-0004.jpg',
  '../img/crazyhorse/IMG-0007.jpg',
  '../img/crazyhorse/IMG-0008.jpg',
  '../img/crazyhorse/IMG-0009.jpg',
  '../img/crazyhorse/IMG-0010.jpg',
  '../img/crazyhorse/IMG-0011.jpg',
  '../img/crazyhorse/IMG-0012.jpg',
  '../img/crazyhorse/IMG-0013.jpg',
  '../img/crazyhorse/IMG-0014.jpg',
  '../img/crazyhorse/IMG-0015.jpg',
  '../img/crazyhorse/IMG-0016.jpg',
  '../img/crazyhorse/IMG-0017.jpg',
  '../img/crazyhorse/IMG-0018.jpg',
  '../img/crazyhorse/IMG-0019.jpg',
  '../img/crazyhorse/crazyhorse-11.jpg',
  '../img/crazyhorse/crazyhorse-12.jpg',
];

const Crazyhorse = () => (
  <Aux>
    <Helmet>
      <title>Crazy Horse</title>
      <meta name="description" content="page crazy horse" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Crazyhorse;
