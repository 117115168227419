import React, { Component } from 'react';
import Layout from './hoc/Layout';
import Aux from './hoc/Auxiliary';
import Helmet from "react-helmet";
// import { Router } from 'react-router-dom';
// import ReactGA from "react-ga";  //Google Analytics
// import { createBrowserHistory } from 'history';

// require('dotenv').config();

export default class App extends Component {
  render () {
    return (
      <Aux>
        <Helmet
          title = "Adam Proust - Photographe"
          meta={[
            {rel:"canonical", href:"https://fineart.adamproust.com"},
            {name:"description", content:"dam Proust photographe paris, bordeaux, still-life, produit, catalogue, corporate, packshot, portrait, mode, fashion, retoucheur, créatif"},
            {property:"og:type", content:"article"},
            {property:"og:title", content:"Adam Proust - Photogaphe"},
            {property:"og:image", content:"article"},
            {property:"og:url", content:"https://fineart.adamproust.com"},
            {name:"google-site-verification", content:process.env.GOOGLE_CHECK_ID}
          ]}
        />
        <Layout/>
      </Aux>
    );
  }
}
