import React from 'react';
import GridMasonry from '../components/GridMasonry';
// import CarouselImage from '../components/CarouselImage';
import Helmet from "react-helmet";
import Aux from '../hoc/Auxiliary';

const images = [
  '../img/peinture/peinture-1.jpg',
  '../img/peinture/peinture-2.jpg',
  '../img/peinture/peinture-3.jpg',
  '../img/peinture/peinture-4.jpg',
  '../img/peinture/peinture-5.jpg',
  '../img/peinture/peinture-6.jpg',
  '../img/peinture/peinture-7.jpg',
  '../img/peinture/peinture-8.jpg',
  '../img/peinture/peinture-9.jpg',
  '../img/peinture/peinture-10.jpg',
  '../img/peinture/peinture-11.jpg',
  '../img/peinture/peinture-12.jpg',
  '../img/peinture/peinture-13.jpg',
  '../img/peinture/peinture-14.jpg',
  '../img/peinture/peinture-15.jpg',
  '../img/peinture/peinture-16.jpg',
  '../img/peinture/peinture-17.jpg',
  '../img/peinture/peinture-18.jpg',
  '../img/peinture/peinture-19.jpg',
  '../img/peinture/peinture-20.jpg',
];

const Peinture = () => (
  <Aux>
    <Helmet>
      <title>Peinture</title>
      <meta name="description" content="page peinture" />
    </Helmet>
    <GridMasonry images={images} />
  </Aux>
);

export default Peinture;
